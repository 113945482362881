import { Box } from "@chakra-ui/react";
import {
  VStack,
  Image,
  getCdnImageSrc,
  useResponsive,
  colors,
  responsive,
} from "goi_common";
import { ReactNode } from "react";
import { MAX_WIDTH } from "@/constants/components";

export default function TrustDirectorSection() {
  const { isBiggerThanLg } = useResponsive();
  return (
    <VStack width="100%" alignItems="center" background={colors.warmGray100}>
      <VStack
        maxWidth={`${MAX_WIDTH}px`}
        width="100%"
        gap="60px"
        css={responsive({
          padding: { base: "60px 24px", lg: "120px 24px" },
        })}
      >
        <VStack>
          <Box
            className="gray900 head_24_eb lg_head_42_b"
            css={responsive({
              textAlign: { base: "center", lg: "left" },
            })}
          >
            서비스 품질에 대한 고집.
            <br />한 번 뿐인 장례, 믿고 맡길 수 있어야 합니다
          </Box>
          <Box
            className="gray600 subtitle_18_sb lg_head_28_m"
            css={responsive({
              paddingTop: "16px",
              textAlign: { base: "center", lg: "left" },
            })}
          >
            신원 및 자격 확인, 평판 조회, 인성/실무{isBiggerThanLg || <br />}{" "}
            면접 등 4단계의 검증 시스템을 통해
            <br />
            믿을 수 있는 장례지도사만 고집합니다
          </Box>
        </VStack>
        <Box
          display="flex"
          gap="16px"
          alignItems="center"
          css={responsive({
            flexDirection: { base: "column", lg: "row" },
          })}
        >
          <MatchSangjoCard
            id="1"
            title="제 부모님처럼 모시겠습니다"
            text="안녕하세요 장례지도사 최서규입니다. 아버지께 못다한 마음을 다른 가족분들께 전해드려야겠다고 생각하고 상조 회사를 설립하게 되었습니다. 초심을 잃지 않고 정말 제 부모님처럼 다른 부모님의 장례를 돕기 위해 노력하고 있습니다."
          />
          <MatchSangjoCard
            id="2"
            title={
              <>
                성직자의 마음으로 <br />
                정성을 다합니다
              </>
            }
            text="처음 같은 마음으로, 항상 진심으로 가족을 모시고 있습니다. 가족과 마지막 작별을 고하는 고인께 격식과 예의를 갖춰 보내드리고 싶습니다. 그토록 사랑했고 소중했던 고인을 멀리 떠나보내는 유가족의 슬픔에 마음을 다해 위로해 드리고, 도와드리고 싶습니다."
          />
          <MatchSangjoCard
            id="3"
            title={
              <>
                장례지도사는
                <br />
                직업이 아닌 소명입니다
              </>
            }
            text="하나님의 부르심(calling)을 받았다고 생각하기에 저의 마음과 행동이 유가족에게 작게나마 위로가 되길 바라는 마음으로 일하고 있습니다. 사랑하는 이를 보내는 가족분들의 슬픔이 너무 큰 상황에, 고인과 유가족이 아름다운 마지막 기억을 갖고 이별할 수 있도록 노력하고 있습니다."
          />
        </Box>
      </VStack>
    </VStack>
  );
}

function MatchSangjoCard({
  id,
  title,
  text,
}: {
  id: string;
  text: string;
  title: ReactNode;
}) {
  const { isBiggerThanLg } = useResponsive();
  return (
    <VStack
      className="white"
      alignItems="center"
      background="black"
      borderRadius="16px"
      padding={"16px 16px 30px"}
      width={"100%"}
      maxWidth="500px"
      height={isBiggerThanLg ? "440px" : "100%"}
    >
      <VStack position="relative">
        <Image
          width="100%"
          src={getCdnImageSrc(`platform/sa/trust-director-${id}.png`)}
          alt=""
        />

        <VStack
          className="subtitle2_b"
          position="absolute"
          css={{ bottom: 20, textAlign: "center" }}
          alignItems="center"
          width="100%"
          gap={6}
        >
          {title}
          <Image
            width={"100px"}
            src={getCdnImageSrc(`platform/sa/trust-directorname-${id}.png`)}
            alt=""
          />
        </VStack>
      </VStack>

      <div css={{ padding: "0px 10px" }} className={"body3_r"}>
        {text}
      </div>
    </VStack>
  );
}
