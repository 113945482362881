import { TABLET_WIDTH, MAX_WIDTH } from "@/constants/components";

import { css } from "@emotion/react";

import {
  colors,
  customEvent,
  GoiLogo,
  HStack,
  Icon,
  NextLink,
  Spacing,
  VStack,
} from "goi_common";

function Footer() {
  return (
    <VStack
      width="100%"
      background="#423a39"
      padding="60px 0px 180px"
      alignItems="center"
      className="caption1 white"
    >
      <VStack maxWidth={`${MAX_WIDTH}px`} width="100%" css={footerContentCss}>
        <GoiLogo color="white" size={36} />
        <Spacing gap={14} />
        <div>(주)고이장례연구소</div>
        <Spacing gap={8} />
        <div css={afterBarWrapCss} className="gray300">
          <a
            href="https://goifuneral.notion.site/49549cb0c8b044a7a9605b8cfe251ce3"
            css={afterBarCss}
          >
            이용약관
          </a>

          <a
            href="https://goifuneral.notion.site/544becd151c946618d151bd9b2659414"
            css={afterBarCss}
            className="font-700 orange650"
          >
            개인정보처리방침
          </a>
          <a
            css={afterBarCss}
            href="https://goifuneral.notion.site/90469f8dfa42468bbc5b44d4b63675e9"
          >
            중요정보 고시사항
          </a>

          <a
            href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=8318701971"
            css={afterBarCss}
          >
            사업자정보확인
          </a>

          <NextLink
            href="tel:1666-9784"
            css={afterBarCss}
            onClick={() => {
              customEvent({
                newGtm: {
                  path: "footer",
                  trigger_and_target: "cs_center-uv_my",
                },
              });
            }}
          >
            고객센터
          </NextLink>
          <NextLink
            href="https://www.goifuneral.co.kr/consultant/search/"
            onClick={() => {
              customEvent({
                newGtm: {
                  path: "footer",
                  trigger_and_target: "footer-uv_consultant-search",
                },
              });
            }}
          >
            상담사 조회
          </NextLink>
        </div>

        <Spacing gap={28} />
        <div className="gray500">
          <div css={afterBarWrapCss}>
            <div css={afterBarNoMarginCss}>대표이사 : 송슬옹 </div>
            <div> 개인정보관리책임자 : 김소현</div>
          </div>
          <div>사업자 등록번호 : 831-87-01971</div>
          <div>통신판매업신고번호 : 2021-서울관악-2417</div>
          <div>전화권유판매업신고번호: 2024-서울관악-0001</div>
          <div>선불식할부거래업등록번호: 서울-2024-제174호</div>
          <div css={afterBarWrapCss}>
            <div css={afterBarNoMarginCss}>Tel : 1666-9784</div>
            <div> 주소 : 서울 관악구 신림로 122 2층</div>
          </div>
          <div css={afterBarWrapCss}>
            <div> 이메일 : contact@goifuneral.co.kr</div>
          </div>
        </div>
        <Spacing gap={12} />
        <div css={rightWrapCss}>
          <Spacing gap={32} />
          <div>
            © {new Date().getFullYear()}. (주)고이장례연구소 ALL RIGHTS
            RESERVED.
          </div>
        </div>
        <Spacing gap={20} />
        <HStack css={iconWrapCss} alignItems="center">
          <NextLink
            href="https://blog.naver.com/PostList.naver?blogId=goifuneral"
            onClick={() => {
              customEvent({
                newGtm: {
                  path: "footer",
                  trigger_and_target: "blog_icon-blog",
                },
              });
            }}
          >
            <Icon src="/blog.svg" width={34} height={15} alt="blog" />
          </NextLink>
          <div css={{ marginLeft: "12px" }} />
          <NextLink
            href="https://post.naver.com/goifuneral"
            onClick={() => {
              customEvent({
                newGtm: {
                  path: "footer",
                  trigger_and_target: "naver_post_icon-post",
                },
              });
            }}
          >
            <Icon src="/naverPost.svg" width={12} height={20} alt="blog" />
          </NextLink>
          <div css={{ marginLeft: "12px" }} />
          <NextLink
            href="https://www.youtube.com/c/고이TV알기쉬운장례정보"
            onClick={() => {
              customEvent({
                newGtm: {
                  path: "footer",
                  trigger_and_target: "youtube_icon-youtube",
                },
              });
            }}
          >
            <Icon src="/youtube.svg" width={24} height={24} alt="blog" />
          </NextLink>
          <div css={{ marginLeft: "12px" }} />
          <NextLink
            href="https://www.instagram.com/goi.funeral/"
            onClick={() => {
              customEvent({
                newGtm: {
                  path: "footer",
                  trigger_and_target: "instagram_icon-instagram",
                },
              });
            }}
          >
            <Icon src="/instagram.svg" width={24} height={24} alt="instagram" />
          </NextLink>
        </HStack>
      </VStack>
    </VStack>
  );
}

const footerContentCss = css({
  padding: "0px 24px",
  [`@media(min-width:${TABLET_WIDTH}px)`]: {
    padding: "0px 40px",
  },
});

const afterBarCss = css({
  marginBottom: "3px",
  "&:after": {
    content: "''",
    margin: "0px",
    color: colors.white,
    fontWeight: 500,
  },
  [`@media(min-width:${TABLET_WIDTH}px)`]: {
    marginBottom: "0px",
    "&:after": {
      content: "'|'",
      margin: "0px 6px",
    },
  },
});

const afterBarNoMarginCss = css({
  "&:after": {
    content: "''",
    margin: "0px",
  },
  [`@media(min-width:${TABLET_WIDTH}px)`]: {
    "&:after": {
      content: "'|'",
      margin: "0px 6px",
    },
  },
});

const afterBarWrapCss = css({
  display: "flex",
  flexDirection: "column",

  [`@media(min-width:${TABLET_WIDTH}px)`]: {
    flexDirection: "row",
  },
});

const rightWrapCss = css({
  width: "240px",
  wordBreak: "keep-all",
  whiteSpace: "pre-line",
  [`@media(min-width:${TABLET_WIDTH}px)`]: {
    width: "100%",
    whiteSpace: "normal",
  },
});

const iconWrapCss = css({
  position: "absolute",
  right: 24,
  [`@media(min-width:${TABLET_WIDTH}px)`]: {
    position: "relative",
    right: 0,
  },
});

export default Footer;
